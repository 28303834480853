<template>
  <div class="applications">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('general.router-role')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #otherHandler="scope">
        <!-- 由于这里的编辑、删除按钮的控制条件和公共组件的不一样，为了公共组件的简洁性这里按钮重写 -->
        <el-button
          class="opt-btn"
          v-if="isUpdate && !scope.row.isSystem && !isSelfRole(scope.row.id)"
          icon="el-icon-edit"
          size="mini"
          type="primary"
          plain
          @click="handleEditData(scope.row.id)"
          >{{ $t('general.edit') }}</el-button
        >
        <el-button
          class="opt-btn"
          v-if="isDelete && !scope.row.isSystem && !isSelfRole(scope.row.id)"
          icon="el-icon-delete"
          size="mini"
          type="danger"
          plain
          @click="handleDeleteCurrentItem(scope.row)"
          >{{ $t('general.delete') }}</el-button
        >
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/role.search'
import { contentTableConfig } from './config/role.table'

import { useRouter } from 'vue-router'
import { usePermission } from '@/hooks/use-permission'
import { getItem } from '@/utils/storage'

// 表格基础配置项
const tableOptions = {
  moduleName: 'user', // 所属的模块
  pageName: 'roles', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: true, // 是否显示添加按钮的插槽
  isShowEditBtn: false,
  isShowDeleteBtn: false,
  pageAction: 'user/getRoleListByPage', // 获取表格的Action
  deleteRecordAction: 'user/handleDeleteRole', // 删除record的Action
  pageCountGetter: 'user/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'user/pageListData' // 获取表格列表数据getters
}
const tableOptionsConfig = computed(() => tableOptions)

const pageContentRef = ref('')
const pageSearchRef = ref('')

const isUpdate = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'edit'
)
const isDelete = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'delete'
)
const userInfo = getItem('userInfo') || {}

const isSelfRole = computed(() => {
  return (role) => {
    const rolesList = userInfo.roleId ? userInfo.roleId.split(',') : []
    return rolesList.includes(role)
  }
})

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const router = useRouter()
const handleViewData = (id) => {
  router.push(`/roles/view/${id}`)
}

const handleEditData = (id) => {
  router.push(`/roles/edit/${id}`)
}

const handleNewData = () => {
  router.push(`/roles/new`)
}

const handleDeleteCurrentItem = (item) => {
  pageContentRef.value.handleDeleteClick(item)
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}
</script>
