export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'name',
      label: 'user.role-name'
    },
    {
      prop: 'description',
      label: 'user.role-description'
    },

    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    {
      label: 'common.app-operation',
      slotName: 'handler',
      width: '310'
    }
  ],
  showIndexColumn: true
}
