// import { getCommonParamsOptions } from '@/utils/common'

export const searchFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'user.role-name'
    },
    {
      field: 'description',
      type: 'input',
      label: 'user.role-description'
    },
    // {
    //   field: 'status',
    //   type: 'select',
    //   label: 'general.status',
    //   isResolveGlobalParams: true,
    //   options: [],
    //   handler: () => getCommonParamsOptions('status')
    // }
    {
      field: 'createTime',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
